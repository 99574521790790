<template lang="">
  <div>
    <b-modal
      :visible="isAddNewUserSidebarActive"
      :title="header"
      centered
      hide-footer
      @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
    >
      <slot name="body" />
    </b-modal>
  </div>
</template>
<script>
import {
  BModal, VBModal,
} from 'bootstrap-vue'

export default {
  components: {
    BModal,
  },
  directives: {
    'b-modal': VBModal,
  },
  model: {
    prop: 'isAddNewUserSidebarActive',
    event: 'update:is-add-new-user-sidebar-active',
  },
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
    header: {
      type: String,
      required: true,
    },
  },
}
</script>
<style lang="">

</style>
