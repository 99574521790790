<template lang="">
  <div>
    <div class="text-right">
      <b-button
        variant="outline-primary"
        @click="isAddNewUserSidebarActive = true"
      >
        <span class="text-nowrap">Add</span>
      </b-button>
    </div>
    <datatable
      class="mt-4"
      :data-of-table="getList"
      :table-columns="fields"
      :total="getTotal"
      :resource="`annonces`"
      :query-search="`title`"
      :is-busy="getLoading"
      :buttons="[{icon:'Edit3Icon', name:'edit',variant:'flat-success'},{icon:'Trash2Icon',name:'remove',variant:'flat-danger'}]"
      @edit="edit"
      @remove="remove"
    />
    <BModal
      :header="header"
      :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
    >
      <template #body>
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >
          <!-- Form -->
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >
            <!-- Title -->
            <validation-provider
              #default="{ errors }"
              name="title"
              rules="required"
            >
              <b-form-group
                label="Titre"
                label-for="title"
              >
                <b-form-input
                  id="title"
                  v-model="annonce.title"
                  :state="errors.length > 0 ? false:null"
                  trim
                />

                <b-form-invalid-feedback>
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Cover -->
            <validation-provider
              #default="{ errors }"
              name="cover"
              rules="required"
            >
              <b-form-group
                label="Cover"
                label-for="cover"
              >
                <b-form-file
                  id="cover"
                  v-model="annonce.file"
                  accept="image/*"
                  :state="errors.length > 0 ? false:null"
                  trim
                />

                <b-form-invalid-feedback>
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              #default="{ errors }"
              name="type"
              rules="required"
            >
              <b-form-group
                label="Choisissez le type"
                label-for="type"
              >
                <v-select
                  v-model="annonce.type"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="options"
                  input-id="pays"
                  label="title"
                  :state="errors.length > 0 ? false:null"
                  :reduce="type => type.id"
                  :clearable="false"
                />

                <b-form-invalid-feedback>
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                <span v-if="loading === false"> {{ update === true ? 'Update' : 'Soumettre' }} </span>
                <span v-else>
                  <b-spinner small />
                </span>
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                @click="hide"
              >
                Annuler
              </b-button>
            </div>

          </b-form>
        </validation-observer>
      </template>
    </BModal>
  </div>
</template>
<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormInput, BFormFile, BFormGroup, BForm, BButton, BSpinner, BFormInvalidFeedback,
} from 'bootstrap-vue'
import {
  required,
} from '@validations'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import datatable from '@/@core/components/datatable/datatable.vue'
import BModal from '@/@core/components/b-modal/BModal.vue'
import vSelect from 'vue-select'

export default {
  components: {
    datatable,
    BModal,
    ValidationProvider,
    ValidationObserver,
    BFormInvalidFeedback,
    BFormInput,
    BFormGroup,
    BForm,
    BFormFile,
    vSelect,
    // BRow,
    // BCol,
    BButton,
    BSpinner,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      fields: [
        {
          key: 'title', label: 'Nom', sortable: true,
        },
        {
          key: 'cover', label: 'Cover', sortable: true,
        },
        {
          key: 'type', label: 'Type', sortable: true,
        },
        'actions',
      ],
      isAddNewUserSidebarActive: false,
      annonce: {
        title: '',
        file: '',
        type: '',
        cover: '',
      },
      items: [],
      options: [
        {
          title: 'Publicite',
          id: 'pub',
        },
        {
          title: 'Annonce',
          id: 'annonce',
        },
      ],
      required,
      loading: false,
      update: false,
      header: 'Add new annonce',
      id: '',
    }
  },
  computed: {
    getList() {
      return this.$store.getters['annonces/list']
    },
    getTotal() {
      return this.$store.getters['annonces/totalItems']
    },
    getLoading() {
      return this.$store.getters['annonces/loading']
    },
  },
  mounted() {
    if (this.$store.getters['annonces/list'].length === 0) {
      this.$store.dispatch('annonces/list', { params: null })
    }
  },
  methods: {
    resetForm() {
      this.annonce = {
        title: '',
        file: '',
        type: '',
        cover: '',
      }
    },
    hide() {
      this.annonce = {
        title: '',
        file: '',
        type: '',
        cover: '',
      }
      this.isAddNewUserSidebarActive = false
    },
    filter(params) {
      const body = params
      this.$store.dispatch('annonces/list', { params: body })
    },
    edit(items) {
      this.annonce = {
        title: items.title,
        file: '',
        type: items.type,
        cover: '',
      }
      this.id = items.id
      this.header = 'Update annonce'
      this.isAddNewUserSidebarActive = true
      this.update = true
    },
    async remove(items) {
      this.id = items.id
      this.confirmButtonColor()
    },
    confirmButtonColor() {
      this.$swal({
        title: 'Êtes-vous sur ?',
        text: 'Vous ne pourrez pas revenir en arrière !',
        icon: 'warning',
        showCancelButton: true,
        timerProgressBar: true,
        confirmButtonText: 'Oui, supprimer!',
        cancelButtonText: 'Annuler',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.deleteData()
        }
      })
    },
    async deleteData() {
      try {
        // const { token } = JSON.parse(localStorage.getItem('user'))
        await this.$store.dispatch('annonces/destroy', { id: this.id, params: null })
        // await this.$http.delete(`sites/${this.id}`, { headers: { Accept: 'application/json', Authorization: `Bearer ${token}` } })
        this.$swal({
          icon: 'success',
          title: 'Suppression!',
          text: 'Element supprimé avec succès',
          customClass: {
            confirmButton: 'btn btn-success',
          },
        })
        this.modalShow = false
      } catch (error) {
        this.$swal({
          icon: 'error',
          title: 'Oops! Something',
          text: 'Nous avons rencontre une erreur',
          customClass: {
            confirmButton: 'btn btn-danger',
          },
        })
      }
    },
    onSubmit() {
      this.$refs.refFormObserver.validate().then(async success => {
        if (success) {
          this.loading = true
          if (this.update === true) {
            await this.$store.dispatch('annonces/update', { id: this.id, params: null, item: this.annonce })
          } else {
            await this.$store.dispatch('annonces/add', { page: 1, item: this.annonce, params: null })
            // eslint-disable-next-line no-return-assign
              .then(() => this.loading = false)
            // eslint-disable-next-line no-return-assign
              .catch(() => this.loading = false)
          }
          this.loading = false
          this.annonce = {
            title: '',
            file: '',
            type: '',
            cover: '',
          }
          this.id = ''
          this.update = false
          this.isAddNewUserSidebarActive = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Operation effectuée',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        }
      })
    },
  },
}
</script>
<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  </style>
